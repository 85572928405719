<template>
  <div>
    <div
      class="social-icon-container"
      :class="webinarInfo.moments.length <= 1 ? 'full-width' : ''"
      v-if="isAnySocialMediaButtonEnable"
    >
      <SocialShare
        :id="optionsObj.currentSelection.value.id"
        :title="translateMoment(optionsObj.currentSelection.value).title"
        :description="translateMoment(optionsObj.currentSelection.value).description"
        :share_buttons="webinarInfo.share_buttons"
        v-if="optionsObj.currentSelection.value.id != -1"
      />
    </div>
    <div class="slider-container">
      <div
        class="rnd-crns"
        :class="webinarInfo.moments.length <= 1 ? 'full-width' : ''"
        :key="optionsObj.currentSelection.value.id"
      >
        <VideoPlayer
          :mid="optionsObj.currentSelection.value.id"
          :poster="optionsObj.currentSelection.value.video_urls.poster_url"
          :video_urls="optionsObj.currentSelection.value.video_urls"
          :subtitleUrls="optionsObj.currentSelection.value.subtitle_urls"
          :subtitles="optionsObj.currentSelection.value.subtitles"
          :continuefromTime="optionsObj.currentSelection.value.end_time"
          :duration="optionsObj.currentSelection.value.duration"
          :videoType="optionsObj.currentSelection.value.id == -1 ? ContentType.Webinar : ContentType.Moment"
          :title="translateMoment(optionsObj.currentSelection.value).title"
          :useDemoOverlay="webinarInfo.use_demo_overlay"
          :height="height"
          v-if="
            !optionsObj.currentSelection.value.video_urls.iframe_url ||
            optionsObj.currentSelection.value.video_urls.iframe_url == ''
          "
          :logoOverlay="webinarInfo.overlay_logo"
          :isgated="webinarInfo.is_gated"
          :ma="webinarInfo.ma"
          :usetheaterMode="usetheaterMode"
          :cta="webinarInfo.cta"
          :post_play="webinarInfo.post_play"
          :autoPlay="autoPlay"
        />
        <IframeVideoPlayer
          v-else
          :iframe_url="optionsObj.currentSelection.value.video_urls.iframe_url"
          :poster="optionsObj.currentSelection.value.video_urls.poster_url"
          :duration="optionsObj.currentSelection.value.duration"
        />
      </div>

      <div
        class="top btn"
        @click="scrollUp()"
        v-if="
          arrowStatusService.arrowStatus.value.veritcalScroll && !arrowStatusService.arrowStatus.value.veritcalScrollAtTop
        "
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24" class="icon-color">
          <path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z" class="icon-color" />
        </svg>
      </div>

      <div
        class="bottom btn"
        @click="scrollDown()"
        v-if="
          arrowStatusService.arrowStatus.value.veritcalScroll && !arrowStatusService.arrowStatus.value.veritcalScrollAtBottom
        "
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24" class="icon-color">
          <path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z" class="icon-color" />
        </svg>
      </div>
      <div class="holder" ref="scrollContainer" v-if="webinarInfo.moments.length > 1">
        <div class="slider">
          <VerticalOption
            :id="'moment-' + m.id"
            v-for="(m, i) in optionsObj.options"
            :key="i"
            :poster_url="m.video_urls.poster_url"
            :title="translateMoment(m).title"
            :description="translateMoment(m).description"
            :isSelected="m.id == optionsObj.currentSelection.value.id"
            @click="changeSelection(m.id)"
            :duration="formatDate(m.duration)"
            :isWebinar="m.id == -1"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { WebinarInfo } from "@/models/WebinarInfo"
import VideoPlayer from "@/components/shared/VideoPlayer.vue"
import IframeVideoPlayer from "@/components/shared/IframeVideoPlayer.vue"
import VerticalOption from "@/components/layouts/VerticalOption.vue"
import { defineComponent, PropType, ref } from "@vue/runtime-core"
import setupOptions from "@/composables/option-handler"
import { ContentType } from "@/models/ContentType"
import { formatDate } from "@/services/DurationFormatter"
import { listenOnScrollForArrows } from "@/services/scrollArrowStatus"
import { EventBusKey, translationKey } from "@/symbols"
import getMomentId from "@/services/ParameterServices"
import SocialShare from "@/components/shared/SocialShare.vue"
import injectStrict from "@/utils/injectStrict"
import { GlobalDataKey } from "@/symbols"
import eventBus from "@/event-bus"
import { Postplay } from "@/models/PostPlayOptions"
import { isMobile } from "@/services/deviceDetection"
import handleAutoPlayEvent from "@/services/handle-autoplay"

export default defineComponent({
  components: { VideoPlayer, IframeVideoPlayer, VerticalOption, SocialShare },
  props: {
    webinarInfo: { type: Object as PropType<WebinarInfo>, required: true },
    usetheaterMode: { type: Boolean, required: false, default: false }
  },
  setup(props) {
    let pos = { top: 0, left: 0, x: 0, y: 0 }
    const scrollContainer = ref<HTMLElement | undefined>()
    var optionsObj = setupOptions(props.webinarInfo)
    var was_dragger = false
    const globalData = injectStrict(GlobalDataKey).value
    let eventbus = injectStrict(EventBusKey)
    var height = ref<Number | null>(null)
    let isAnySocialMediaButtonEnable = false
    if (props.webinarInfo.share_buttons != null)
      isAnySocialMediaButtonEnable = Boolean(
        Object.values(props.webinarInfo.share_buttons).reduce((prev, now) => false || prev || now)
      )
    var arrowStatusService = listenOnScrollForArrows(scrollContainer)
    var autoPlay = ref(false)
    const { translateMoment } = injectStrict(translationKey)
    return {
      optionsObj,
      ContentType,
      scrollContainer,
      pos,
      was_dragger,
      formatDate,
      eventbus,
      height,
      arrowStatusService,
      autoPlay,
      globalData,
      isAnySocialMediaButtonEnable,
      translateMoment
    }
  },
  created() {
    this.eventbus.on("setHeight", (value: any) => {
      this.height = value
    })
  },
  mounted() {
    this.addScroller()
    this.focus()
    this.arrowStatusService.setup()
    if (!isMobile()) {
      eventBus.on(Postplay.AUTO_PLAY, (mid: any) => {
        const currentMomentIndex = window.momentList.findIndex(
          m => m.mid == mid.mid && m.instanceId == this.globalData.instanceId
        )
        if (currentMomentIndex + 1 < window.momentList.length) {
          if (window.momentList[currentMomentIndex + 1].instanceId != this.globalData.instanceId) return
          this.autoPlay = true
          const nextMoment = window.momentList[currentMomentIndex + 1]
          this.optionsObj.updateCurrentSelection(`${nextMoment.mid}`)
        }
      })
    }
  },
  unmounted() {
    this.arrowStatusService.unset()
  },
  methods: {
    changeSelection(id: string) {
      this.autoPlay = false
      if (!this.was_dragger) this.optionsObj.updateCurrentSelection(id)
      else this.was_dragger = false
    },
    addScroller() {
      var self = this
      self.scrollContainer?.addEventListener("mousedown", (e: any) => mouseDownHandler(e))
      const mouseDownHandler = function (e: any) {
        if (!self.scrollContainer) return
        self.pos = {
          // The current scroll
          left: self.scrollContainer.scrollLeft,
          top: self.scrollContainer.scrollTop,
          // Get the current mouse position
          x: e.clientX,
          y: e.clientY
        }
        self.scrollContainer.style.cursor = "grabbing"
        self.scrollContainer.style.userSelect = "none"
        document.addEventListener("mousemove", self.mouseMoveHandler)
        document.addEventListener("mouseup", self.mouseUpHandler)
      }
    },
    mouseMoveHandler(e: any) {
      if (!this.scrollContainer) return
      // How far the mouse has been moved
      const dx = e.clientX - this.pos.x
      const dy = e.clientY - this.pos.y
      if (dy > 5 || dy < -5) {
        this.was_dragger = true
      }
      // Scroll the element
      this.scrollContainer.scrollTop = this.pos.top - dy
      //this.scrollContainer.scrollLeft = this.pos.left - dx
    },
    mouseUpHandler() {
      document.removeEventListener("mousemove", this.mouseMoveHandler)
      document.removeEventListener("mouseup", this.mouseUpHandler)
      if (!this.scrollContainer) return
      this.scrollContainer.style.cursor = "default"
      this.scrollContainer.style.removeProperty("user-select")
    },
    scrollUp() {
      if (!this.scrollContainer) return
      this.scrollContainer.scrollTop = this.scrollContainer.scrollTop - 104
      //this.scrollContainer.scrollLeft = this.pos.left - dx
    },
    scrollDown() {
      if (!this.scrollContainer) return
      this.scrollContainer.scrollTop = this.scrollContainer.scrollTop + 104
      //this.scrollContainer.scrollLeft = this.pos.left - dx
    },
    focus(i: string | null = null) {
      const url = window.location.search
      if (url.length == 0) return
      var id = i ? i : getMomentId(url)
      const momentdiv = document.getElementById("moment-" + id) as HTMLElement
      if (momentdiv) {
        this.changeSelection(id)
        if (this.scrollContainer) this.scrollContainer.scrollIntoView(true)
        momentdiv.scrollIntoView(true)
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.icon-color {
  stroke: transparent;
  fill: var(--theme-contrast);
}

.btn {
  position: absolute;
  display: flex;
  width: 34%;
  right: 0px;
  border: 0px;
  align-content: center;
  justify-content: center;
  z-index: 1;
  cursor: pointer;

  svg {
    background-color: var(--theme-color-9);
    border-radius: 360px;
    width: 34px;
    height: 34px;
  }

  svg:hover {
    transform: scale(1.2);
  }
}

.top {
  top: 0px;
  transform: translateY(-50%);

  svg {
    box-shadow: 2px 0px rgba(0, 0, 0, 0.4);
  }
}

.bottom {
  bottom: 0px;
  transform: translateY(50%) rotate(180deg);

  svg {
    box-shadow: -2px 0px rgba(0, 0, 0, 0.4);
  }
}

.slider {
  position: absolute;
  display: flex;
  row-gap: 10px;
  width: 100%;
  justify-content: flex-end;
  flex-direction: column;
}

.slider-container {
  display: flex;
  flex-direction: row;
  height: fit-content;
  position: relative;
  margin-top: 20px;
  margin-bottom: 15px;
}

.rnd-crns {
  width: 66%;
}

.holder {
  position: absolute;
  height: 100%;
  width: 34%;
  right: 0px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.holder::-webkit-scrollbar {
  display: none;
}

.social-icon-container {
  min-height: 1.875em;
  margin: 0px;
  margin-bottom: -10px;
  width: 66%;
  display: flex;
  justify-content: right;
}
.full-width {
  min-width: 100%;
  width: 100%;
}
</style>
