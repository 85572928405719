<template>
  <div class="moments" ref="holder" :style="positioningCss">
    <div class="moment" v-for="(m, i) in moments" :key="i">
      <div class="small-dot"></div>
      <div class="moment_highlight line customDot" :class="getheight(m.duration)"></div>
      <Moment
        class="moment-elm"
        :moment="m"
        :useDemoOverlay="useDemoOverlay"
        :logoOverlay="logoOverlay"
        :isgated="isgated"
        :ma="ma"
        :usetheaterMode="usetheaterMode"
        :share_buttons="share_buttons"
        :cta="cta"
        :post_play="post_play"
      />
    </div>
    <div class="timeline"></div>
  </div>
</template>
<script lang="ts">
import { MomentInfo } from "@/models/MomentInfo"
import Moment from "@/components/TimelineComponents/Moment.vue"
import { computed, defineComponent, PropType, Ref, ref } from "vue"
import injectStrict from "@/utils/injectStrict"
import { EventBusKey, LoggerKey } from "@/symbols"
import { StringToSeconds } from "@/services/DurationFormatter"
import LogoOverlayData from "@/models/LogoOverlayData"
import { MAData } from "@/models/ma/MAData"
import { ShareButtons } from "@/models/ShareButtons"
import { RelatedResourceData } from "@/models/RelatedResourceData"

export default defineComponent({
  components: { Moment },
  props: {
    moments: { type: Object as PropType<Array<MomentInfo>>, required: true },
    useDemoOverlay: { type: Boolean, required: false, default: false },
    cta: { type: Object as PropType<RelatedResourceData>, required: false, default: null },
    logoOverlay: { type: Object as PropType<LogoOverlayData>, required: false, default: null },
    isgated: Boolean,
    ma: { type: Object as PropType<MAData>, required: false, default: null },
    usetheaterMode: { type: Boolean, required: false, default: false },
    post_play: { type: String, required: false, default: undefined },
    share_buttons: { type: Object as PropType<ShareButtons | null>, required: false, default: null }
  },

  setup(props) {
    let moment_flag = 0
    let holder = ref<HTMLElement>()
    let logger = injectStrict(LoggerKey)
    let eventbus = injectStrict(EventBusKey)
    let width = ref(0)

    const positioningCss = {
      "--video-height": "43%",
      "--left-video-offset": "250px",
      "--right-video-offset": "50px",
      "--line_small": "50px",
      "--line_medium": "100px",
      "--line_large": "150px",
      "padding-bottom": props.moments.length % 2 == 0 ? `250px!important` : `50px !important`
    }
    return {
      positioningCss,
      holder,
      logger,
      eventbus,
      width,
      moment_flag
    }
  },
  created() {
    window.addEventListener("resize", this.resize)
  },
  methods: {
    resize() {
      this.width = this.holder?.clientWidth ?? 0
    },
    getheight(duration: string): string {
      let dur = StringToSeconds(duration)
      if (dur > 0 && dur <= 60) return "line1"
      else if (dur > 60 && dur <= 120) return "line2"
      else return "line3"
    }
  }
})
</script>
<style lang="scss" scoped>
@import "../../assets/moment-focus.css";
@import "../../assets/timeline.scss";
@import "../../../node_modules/cleanslate/cleanslate.css";
*::before {
  content: "" !important;
  background: none !important;
  width: 0 !important;
  height: 0 !important;
  border-radius: 0 !important;
}
button,
input[type="submit"] {
  box-shadow: none !important;
}
.customDot::before,
.customDot::after {
  content: "";
  background: var(--theme-color);
  width: 0px;
  height: 0px;
  border-radius: 100px;
  padding: 3.5px;
  position: absolute;
  transform: translateX(-1.2px) translateY(-2.5px);
}
.customDot::after {
  bottom: -2px;
}
</style>
