<template>
  <div id="aw--c" :style="themeCss">
    <div id="aw-sl" style="font-size: 1em !important">
      <SeoJsonLdComponentVue :webinarInfo="webinarInfo" v-if="webinarInfo != undefined" />
      <TheaterPlayer
        :videos="lightboxVideos"
        :logoOverlay="webinarInfo.overlay_logo"
        :useDemoOverlay="webinarInfo.use_demo_overlay"
        :themeCss="themeCss"
        :ma="webinarInfo.ma"
        :cta="webinarInfo.cta"
        :isGated="webinarInfo.is_gated"
        :post_play="webinarInfo.post_play"
        v-if="webinarInfo && lightboxVideos && lightboxVideos.length > 0 && usetheaterMode == true"
      />
      <div class="wc" v-if="webinarInfo != undefined">
        <TranslationDropdown :languages="webinarInfo.translation_language_codes" v-if="webinarInfo != undefined" />
        <MAGate :ma="webinarInfo.ma" :themeCss="themeCss" />
        <TitleTrailerCompoent
          :widgetData="webinarInfo"
          :usetheaterMode="usetheaterMode"
          v-if="webinarInfo.is_info_tab_visible == true"
        />
        <FullVideoCompoment
          v-if="webinarInfo.is_full_video_visible && webinarInfo.is_full_video_on_top"
          :fullvideo="webinarInfo.video_urls"
          :show-head="webinarInfo.moments.length > 0"
          :duration="webinarInfo.duration"
          :webid="webinarInfo.id"
          :title="webinarInfo.title"
          :subtitleUrls="webinarInfo.subtitle_urls"
          :subtitles="webinarInfo.subtitles"
          :useDemoOverlay="webinarInfo.use_demo_overlay"
          :logoOverlay="webinarInfo.overlay_logo"
          :isgated="webinarInfo.is_gated"
          :ma="webinarInfo.ma"
          :usetheaterMode="usetheaterMode"
        />
        <div class="m-r align-self-center row">
          <div
            class="col-12 col-md-6 moment"
            :id="'moment-' + m.id"
            v-for="(m, i) in webinarInfo.moments"
            :key="i"
            style="padding-top: 30px !important"
          >
            <div class="mom-v-h" :style="i % 2 == 0 ? 'padding-right: 30px' : 'padding-left: 30px'">
              <div class="row">
                <div class="rnd-crns" style="width: 100% !important">
                  <VideoPlayer
                    v-if="!m.video_urls.iframe_url || m.video_urls.iframe_url == ''"
                    :video_urls="m.video_urls"
                    :duration="m.duration"
                    :mid="m.id"
                    :title="translateMoment(m).title"
                    :subtitleUrls="m.subtitle_urls"
                    :subtitles="m.subtitles"
                    :continuefromTime="m.end_time"
                    :videoType="ContentType.Moment"
                    :useDemoOverlay="webinarInfo.use_demo_overlay"
                    :logoOverlay="webinarInfo.overlay_logo"
                    :isgated="webinarInfo.is_gated"
                    :ma="webinarInfo.ma"
                    :cta="webinarInfo.cta"
                    :usetheaterMode="usetheaterMode"
                    :post_play="webinarInfo.post_play"
                  />
                  <IframeVideoPlayer
                    v-else
                    :iframe_url="m.video_urls.iframe_url"
                    :poster="m.video_urls.poster_url"
                    :duration="m.duration"
                  />
                </div>
              </div>
              <div class="row">
                <div class="i-h">
                  <div class="row">
                    <p v-html="translateMoment(m).title" class="mom-t" v-if="m.title" />
                    <div v-html="translateMoment(m).description" class="mom-d" />
                  </div>
                  <div class="row">
                    <SocialShare
                      :id="m.id"
                      :title="translateMoment(m).title"
                      :description="translateMoment(m).description"
                      :share_buttons="webinarInfo.share_buttons"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FullVideoCompoment
          v-if="webinarInfo.is_full_video_visible && !webinarInfo.is_full_video_on_top"
          :fullvideo="webinarInfo.video_urls"
          :show-head="webinarInfo.moments.length > 0"
          :duration="webinarInfo.duration"
          :webid="webinarInfo.id"
          :subtitleUrls="webinarInfo.subtitle_urls"
          :subtitles="webinarInfo.subtitles"
          :useDemoOverlay="webinarInfo.use_demo_overlay"
          :title="webinarInfo.title"
          :logoOverlay="webinarInfo.overlay_logo"
          :isgated="webinarInfo.is_gated"
          :ma="webinarInfo.ma"
          :usetheaterMode="usetheaterMode"
        />
        <div id="parmonic-resources">
          <RelatedResourceComponent :resources="webinarInfo.related_resources" />
        </div>
        <div id="parmonic-speakers">
          <SpeakerSection :speakers="webinarInfo.speakers" />
        </div>
      </div>
      <GridSkeleton :loadingmsg="loadingmsg" v-else />
    </div>
  </div>
</template>
<script lang="ts">
import "@/assets/_base.scss"
import { computed, defineComponent, getCurrentInstance, ref } from "vue"
import { WebinarInfo } from "@/models/WebinarInfo"
import { AwEventType } from "@/models/AwEventType"
import setThemeData from "@/services/setThemeData"
import { AnalyticsClient } from "@/services/AnalyticsService"
import { GoogleAnalyticsClient } from "@/services/GoogleAnalyticsService"
import { WebinarDataService } from "@/services/WebinarDataServices"
import { container } from "tsyringe"
import { getFullVideo } from "@/services/getFullVideoData"
import injectStrict from "@/utils/injectStrict"
import { GlobalDataKey, LoggerKey, translationKey } from "@/symbols"
import VideoPlayer from "@/components/shared/VideoPlayer.vue"
import FullVideoCompoment from "@/components/shared/FullVideoComponent.vue"
import IframeVideoPlayer from "@/components/shared/IframeVideoPlayer.vue"
import RelatedResourceComponent from "@/components/shared/RelatedResourceComponent.vue"
import { focusOnMoment } from "@/services/momentFocusService"
import VueSocialSharing from "vue-social-sharing"
import { ContentType } from "@/models/ContentType"
import { setupSocialShare } from "@/composables/social-share"
import { AwEventStoreV2 } from "@/models/AwEventStoreV2"
import TheaterPlayer from "@/components/shared/TheaterPlayer.vue"
import MAGate from "@/components/MA/MAGate.vue"
import getCssVariables from "@/utils/getCssVariables"
import { isIOS } from "@/services/deviceDetection"
import SeoJsonLdComponentVue from "@/components/shared/SeoJsonLdComponent.vue"
import momentAutoOpenTheatreMode from "@/composables/shared/moment-autoopen-theatre"
import { EventBusKey } from "@/symbols"
import TitleTrailerCompoent from "@/components/shared/InfoTab.vue"
import SocialShare from "@/components/shared/SocialShare.vue"
import getTheaterVideos from "@/utils/getTheaterVideos"
import SpeakerSection from "@/components/ResellerLayout/SpeakerSection.vue"
import GridSkeleton from "@/components/skeletons/grid.vue"
import eventBus from "@/event-bus"
import { Postplay } from "@/models/PostPlayOptions"
import handleAutoPlayEvent from "@/services/handle-autoplay"
import TranslationDropdown from "@/components/shared/Translation.vue"

const AmplilyWidget = defineComponent({
  components: {
    SocialShare,
    SeoJsonLdComponentVue,
    VideoPlayer,
    FullVideoCompoment,
    IframeVideoPlayer,
    RelatedResourceComponent,
    MAGate,
    TheaterPlayer,
    TitleTrailerCompoent,
    SpeakerSection,
    GridSkeleton,
    TranslationDropdown
  },
  props: {
    fetchId: { type: String, required: true }
  },
  setup() {
    let webinarId = ref(-1)
    let webinarInfo = ref<WebinarInfo | null>(null)
    let loadingmsg = ref("")
    const globalData = injectStrict(GlobalDataKey).value
    const themeCss = computed(() => getCssVariables(globalData.theme_data))
    const dataService = container.resolve(WebinarDataService)
    const analytics = container.resolve(AnalyticsClient)
    const gaAnalytics = container.resolve(GoogleAnalyticsClient)
    let logger = injectStrict(LoggerKey)
    let socialShareUrl = ref(globalData.socialShareUrl)
    var app = getCurrentInstance()
    if (!app) throw new Error("app cannot be null")
    app.appContext.app.use(VueSocialSharing)
    const { registerSocialShare } = setupSocialShare()
    let lightboxVideos = computed(() => getTheaterVideos(webinarInfo))
    let usetheaterMode = computed(
      () =>
        webinarInfo.value &&
        webinarInfo.value.use_theater_mode == true &&
        !!window.parmonic_disable_theater_mode == false &&
        !isIOS(navigator.userAgent, navigator.maxTouchPoints)
    )
    let eventbus = injectStrict(EventBusKey)
    const { translateMoment } = injectStrict(translationKey)
    return {
      usetheaterMode,
      lightboxVideos,
      logger,
      dataService,
      webinarInfo,
      themeCss,
      globalData,
      webinarId,
      loadingmsg,
      analytics,
      socialShareUrl,
      ContentType,
      gaAnalytics,
      eventbus,
      Postplay,
      translateMoment,
      registerSocialShare
    }
  },
  mounted() {
    handleAutoPlayEvent(this.globalData.instanceId)
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.dataService.getData(this.fetchId).then(async returnValue => {
        this.loadingmsg = "The video could not be loaded."
        if (returnValue == undefined) {
          this.logger!.error(`Parmonic Widget error: Webinar not found`)
          return
        }
        this.webinarInfo = returnValue as WebinarInfo
        this.webinarId = this.webinarInfo.id
        setThemeData(this.webinarInfo.theme_data, this.globalData)
        this.analytics.setUp(this.webinarId, null, null, null, this.globalData.analyticsEventsCaptureEndPoint, this.logger)
        this.gaAnalytics.setUp(this.webinarId, this.logger)
        var eventV2: AwEventStoreV2 = { eventType: AwEventType.WidgetViewed, type: ContentType.FullWidget }
        this.analytics.register(eventV2, null)
        this.globalData.fullVideoInfo = getFullVideo(this.webinarInfo)
        setTimeout(() => {
          focusOnMoment()
          momentAutoOpenTheatreMode(this.globalData.instanceId, this.eventbus)
        }, 500)
      })
    }
  }
})
export default AmplilyWidget
</script>
<style lang="scss" scoped>
@import "../assets/moment-focus.css";
@import "../assets/grid.css";
@import "../assets/vendor/bootatrap-grid.css";
@import "../assets/skeleton.css";
</style>
