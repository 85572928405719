<template>
  <div
    :class="`option-holder`"
    :aria-label="`${translateLabels('Play')}-${moment.title ?? moment.description ?? 'video'}`"
    @click="focus()"
    ref="tile"
  >
    <VideoPlayer
      v-if="!moment.video_urls.iframe_url || moment.video_urls.iframe_url == ''"
      :video_urls="moment.video_urls"
      :duration="moment.duration"
      :mid="moment.id"
      :title="translateMoment(moment).title"
      :subtitleUrls="moment.subtitle_urls"
      :subtitles="moment.subtitles"
      :continuefromTime="moment.end_time"
      :videoType="ContentType.Moment"
      :useDemoOverlay="useDemoOverlay"
      :logoOverlay="logoOverlay"
      :isgated="isgated"
      :ma="ma"
      :cta="cta"
      :usetheaterMode="usetheaterMode"
      :post_play="post_play"
      class="rnd-crns"
    />
    <IframePlayer
      v-else
      :iframe_url="moment.video_urls.iframe_url"
      :poster="moment.video_urls.poster_url"
      :duration="moment.duration"
    />
    <div class="title" v-if="moment.title" v-html="stripHtmlTags(translateMoment(moment).title)"></div>
    <div class="description" v-if="moment.description" v-html="stripHtmlTags(translateMoment(moment).description)"></div>
    <SocialShare
      :id="moment.id"
      :title="translateMoment(moment).title"
      :description="translateMoment(moment).description"
      :share_buttons="share_buttons"
    />
  </div>
</template>

<script lang="ts">
import VideoPlayer from "@/components/shared/VideoPlayer.vue"
import IframePlayer from "@/components/shared/IframeVideoPlayer.vue"
import { defineComponent, PropType, ref } from "@vue/runtime-core"
import injectStrict from "@/utils/injectStrict"
import { GlobalDataKey, translationKey } from "@/symbols"
import { stripHtmlTags } from "@/utils/stripHtmlTags"
import { MomentInfo } from "@/models/MomentInfo"
import LogoOverlayData from "@/models/LogoOverlayData"
import { MAData } from "@/models/ma/MAData"
import { ContentType } from "@/models/ContentType"
import { ShareButtons } from "@/models/ShareButtons"
import SocialShare from "@/components/shared/SocialShare.vue"
import { setupSocialShare } from "@/composables/social-share"
import { RelatedResourceData } from "@/models/RelatedResourceData"

export default defineComponent({
  components: { VideoPlayer, IframePlayer, SocialShare },
  props: {
    moment: { type: Object as PropType<MomentInfo>, required: true },
    usetheaterMode: { type: Boolean, required: false, default: false },
    logoOverlay: { type: Object as PropType<LogoOverlayData>, required: false, default: null },
    isgated: Boolean,
    ma: { type: Object as PropType<MAData>, required: false, default: null },
    cta: { type: Object as PropType<RelatedResourceData>, default: null },
    useDemoOverlay: { type: Boolean, required: false, default: false },
    post_play: { type: String, required: false, default: undefined },
    share_buttons: { type: Object as PropType<ShareButtons | null>, required: false, default: null }
  },
  setup() {
    const globalData = injectStrict(GlobalDataKey).value
    let socialShareUrl = ref(globalData.socialShareUrl)
    const { registerSocialShare } = setupSocialShare()
    const { translateMoment, translateLabels } = injectStrict(translationKey)
    return { stripHtmlTags, ContentType, translateMoment, translateLabels }
  },
  methods: {
    focus() {
      const elm = this.$refs.tile as HTMLDivElement
      elm.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" })
    }
  }
})
</script>

<style lang="scss" scoped>
.pw-play-btn {
  fill: var(--theme-color);
}
.option-holder {
  position: relative;
  width: 28.875em;
  min-width: 159px;
  padding: 0.375em;
  border-radius: 6px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
@media screen and (max-width: 750px) {
  .option-holder {
    width: 14.875em;
  }
}
.title {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  margin-bottom: 0.5em;
  color: var(--font-color);
  cursor: pointer;
  margin-top: 10px;
}
.description {
  color: var(--font-color);
  -webkit-box-orient: vertical;
  display: -webkit-box;
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
  text-align: left;
  align-self: flex-start;
  cursor: pointer;
  width: 100% !important;
  margin-bottom: 0.75em !important;
  padding: 0px 0.25em !important;
}
</style>
